<template>
      <div class="card">
        <Toast position="top-center" />
        <DataTable stripedRows :value="secretlist" responsiveLayout="scroll">
            <Column field="accountName" header="名称"></Column>
            <Column field="accountId" header="账号"></Column>
            <Column field="code" header="验证码">
                <template #body="{data}">
                        <span style="font-size: 25px;color:#337ab7">{{data.code}}</span><Button icon="pi pi-clone" class="p-button-rounded p-button-outlined p-button-sm" style="border:none;" @click="oncopy(data.code)"/>
                </template>
            </Column>
            <Column header="有效时间">
                <template #body>
                    <Knob v-model="remaintime" :valueColor="remaintimecolor" :max="29" :size="50" :textColor="'50'" readonly />
                </template>
            </Column>
        </DataTable>
      </div>
</template>

<script>
    import sjcl from '../service/sjcl';
    import Service from '../service/Service';
    import useClipboard from 'vue-clipboard3';
    export default {
        data(){
            return{
                secretlist:null,
                systemservice:null,
                remaintime:0,
                remaintimecolor:'#337ab7',
            }
        },
        created() {
        this.service = new Service();
    },
        mounted(){
            var url='system/getcodelist.php';
            this.service.sendHttp(url).then(res=>res.json()).then(data => {
                this.secretlist = data; 
            });
            setInterval(this.update,1000);
        },
        methods:{
            getCode(K){
                var C = Math.floor((new Date() - 0) / 30000);
                var key = sjcl.codec.base32.toBits(K);
                var count = [((C & 0xffffffff00000000) >> 32), C & 0xffffffff];
                var otplength = 6;
                var hmacsha1 = new sjcl.misc.hmac(key, sjcl.hash.sha1);
                var code = hmacsha1.encrypt(count);
                var offset = sjcl.bitArray.extract(code, 152, 8) & 0x0f;
                var startBits = offset * 8;
                var endBits = startBits + 4 * 8;
                var slice = sjcl.bitArray.bitSlice(code, startBits, endBits);
                var dbc1 = slice[0];
                var dbc2 = dbc1 & 0x7fffffff;
                var otp = dbc2 % Math.pow(10, otplength);
                var result = otp.toString();
                while (result.length < otplength){
                    result = '0' + result;
                }
                return result;
            },
            update(){
                var d = new Date();
                var time =59- d.getSeconds();
                if(time>=30)
                time=time-30;
                if(time<5)
                this.remaintimecolor='#F44331';
                if(time==29){
                    this.remaintimecolor='#337ab7';
                    for(var x in this.secretlist){
                        this.secretlist[x].code=this.getCode(this.secretlist[x].secret);
                    }
                }
                this.remaintime=time;
            },
            oncopy(text){
            const { toClipboard } = useClipboard();
            toClipboard(text).then(
                ()=>{this.$toast.add({severity:'success', detail:'复制成功！', life: 1000});},
                ()=>{this.$toast.add({severity:'error', detail:'复制失败！', life: 1000});});
        }
        }
    }
</script>